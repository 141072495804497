import { TronLinkAdapter } from '@tronweb3/tronwallet-adapter-tronlink'
import { WalletConnectAdapter } from '@tronweb3/tronwallet-adapter-walletconnect'
import TronWeb from 'tronweb'
import { toNonExponential, mul, divide, pow, lt } from '@/utils/calculation'
import collectionABI from '@/web3/abis/tronCollection.json'
import payoutABI from '@/web3/abis/tronPayout.json'
import payoutControlABI from '@/web3/abis/payoutControl.json'
import bytecode from '@/web3/byteCode/tron.js'
import payoutByteCode from '@/web3/byteCode/tronPayout.js'
import { getAddressArr, delay } from '@/utils/common'
import {
  addContract,
  completeContract,
  deleteContract,
  completeDeleteContract,
  addPayoutContract,
} from '@/api/contract'
import { APIResponse, RESPONSE_TYPE } from './response'
import { BalanceNotEnough, ConnectWalletError, BalanceMinimum } from './errors'

export default class TronAdapter {
  constructor({ chainId, wallet, options = {} }) {
    this.wallet = wallet // 钱包类型
    // 钱包初始化参数
    this.options = {
      ...options,
    }
    this.provider = null // 提供者
    this.tronWeb = null
    this.accountInfo = null // 账户信息
    this.chainId = chainId
  }

  // 创建provider
  createProvider() {
    if (this._isTronLink()) {
      return new TronLinkAdapter()
    } else {
      return new WalletConnectAdapter(this.options)
    }
  }

  // 连接
  async connect() {
    try {
      this.provider = this.createProvider()
      if (!this.provider) return
      await this.provider.connect()
      this.tronWeb = this.buildTronWeb()
      await this.switchNetwork(this.chainId)
    } catch (e) {
      console.log('connect', e)
      throw new ConnectWalletError(e?.message || e)
    }
  }

  async send(method, params) {
    try {
      const request =
        this.provider._wallet._client?.request || this.provider._wallet.request
      if (this._isTronLink()) {
        return request({
          method,
          params,
        })
      }
    } catch (e) {
      console.log('send', e)
      throw e
    }
  }

  // 切换网络
  async switchNetwork(chainId) {
    try {
      console.log('switchNetwork chainId before', chainId)
      await this.send('wallet_switchEthereumChain', [{ chainId }])
      await delay(1000)
      await this.getAccountInfo()
      console.log('switchNetwork chainId after', this.accountInfo.chainId)
    } catch (switchError) {
      throw switchError
    }
  }

  // tronweb方法初始化
  buildTronWeb() {
    const isShasta = this.options?.network === 'Shasta'
    const options = {
      fullHost: isShasta
        ? 'https://api.shasta.trongrid.io'
        : 'https://api.trongrid.io',
    }
    if (!isShasta) {
      options.headers = {
        'TRON-PRO-API-KEY': this.options?.tronApiKey,
      }
    }
    if (this.wallet === 'TronLink') {
      return window.tronWeb || new TronWeb(options)
    } else {
      const tronWeb = new TronWeb(options)
      tronWeb.setAddress(this.provider.address)
      return tronWeb
    }
  }

  // 获取用户信息
  async getAccountInfo() {
    try {
      const data = {
        account: this.provider.address || '',
        chainId: '',
      }
      if (this._isTronLink()) {
        // 初次连接需要重复调用一次
        if (!this.provider._wallet.tronWeb) {
          return await this.connect()
        }
        const currentNetwork = await this.provider.network()
        if (currentNetwork?.chainId) {
          data.chainId = currentNetwork?.chainId
        }
      } else {
        if (this.provider._wallet?._network) {
          data.chainId = this.provider._wallet?._network.replace('tron:', '')
        }
      }
      this.accountInfo = data
    } catch (e) {
      throw e
    }
  }

  // 断开连接
  async disconnect() {
    this.provider?.disconnect() // 断开连接
    this.provider = null // 提供者
    this.accountInfo = null // 账户信息
  }

  _isTronLink() {
    return this.wallet === 'TronLink'
  }
  // 签名
  async signMessage(message) {
    try {
      const result = await this.provider?.signMessage(message)
      return result
    } catch (err) {
      throw err
      console.log('signError', err)
    }
  }

  // 获取代币小数位
  async getDecimals(tokenAddress) {
    try {
      const contract = await this.tronWeb.contract().at(tokenAddress)
      const decimals = await contract.methods.decimals().call()
      return this.tronWeb.toDecimal(decimals)
    } catch (error) {
      throw error
    }
  }

  // 获取代币余额
  async getTokenBalance(tokenAddress, address) {
    if (!this.tronWeb) return null
    try {
      const contract = await this.tronWeb.contract().at(tokenAddress)
      const balance = await contract.methods.balanceOf(address).call()
      const decimals = await contract.methods.decimals().call()
      return toNonExponential(
        divide(
          this.tronWeb.toDecimal(balance),
          pow(10, this.tronWeb.toDecimal(decimals))
        )
      )
    } catch (error) {
      throw error
    }
  }

  // 查询津贴
  async getAllowance(tokenAddress, from, address) {
    try {
      const contract = await this.tronWeb.contract().at(tokenAddress)
      const allowance = await contract.methods.allowance(from, address).call()
      return this.tronWeb.toDecimal(allowance)
    } catch (error) {
      throw error
    }
  }

  // 签名交易
  async signTransaction(transaction, maxAttempts = 3) {
    let res = null
    for (let i = 0; i < maxAttempts; i++) {
      res = this._isTronLink()
        ? await this.tronWeb.trx.sign(transaction)
        : await this.provider.signTransaction(transaction)
      if (res) break
    }
    console.log('signTransaction', res)
    return res
  }

  // 调合约方法
  async sendContractMethod(address, functionSelector, parameter, abi) {
    try {
      let transaction = null
      const feeLimit = 150_000_000
      const from = this.accountInfo.account

      if (this._isTronLink()) {
        const contract = abi
          ? await this.tronWeb.contract(abi, address)
          : await this.tronWeb.contract().at(address)
        console.log('contractcontract', contract)
        const params = parameter.map((item) => item.value)
        transaction = await contract.methods[functionSelector](...params).send({
          feeLimit,
          from,
        })
        console.log('transaction', transaction)
      } else {
        const tx = await this.tronWeb.transactionBuilder.triggerSmartContract(
          address,
          functionSelector,
          {
            feeLimit,
          },
          parameter,
          from
        )
        console.log('tx', JSON.stringify(tx))
        const signedTx = await this.signTransaction(tx.transaction)
        if (!signedTx) return null
        console.log('signedTx', signedTx)
        const rowTransaction = await this.tronWeb.trx.sendRawTransaction(
          signedTx
        )
        if (rowTransaction?.result && rowTransaction?.txid) {
          transaction = rowTransaction.txid
        }
        console.log('transaction', transaction)
      }
      if (transaction) {
        const flag = await this.pollTxidStatus(transaction)
        return flag ? transaction : false
      }
      return false
    } catch (error) {
      throw error
    }
  }

  // 申请津贴
  async sendApprove(tokenAddress, from, address, value) {
    try {
      const functionSelector = 'approve(address,uint256)'
      const parameter = [
        { type: 'address', value: address },
        { type: 'uint256', value },
      ]
      return await this.sendContractMethod(
        tokenAddress,
        functionSelector,
        parameter
      )
    } catch (error) {
      throw error
    }
  }

  // 充值
  async _transferToken(
    { address, tokenAddress, amount, orderId },
    msgType,
    promiEvent
  ) {
    const apiResponse = new APIResponse(msgType)
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.tronWeb || !this.accountInfo || !promiEvent.eventEmitter) {
        throw new Error('invalid provider')
      }

      const from = this.accountInfo.account
      const balance = await this.getTokenBalance(tokenAddress, from)
      if (lt(balance, amount)) {
        // 余额不足
        throw new BalanceNotEnough('balance not enough')
      }

      const { eventEmitter, resolve } = promiEvent
      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.AUTH))

      const decimals = await this.getDecimals(tokenAddress)
      const value = mul(amount, pow(10, decimals))

      const allowance = await this.getAllowance(tokenAddress, from, address)

      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.AUTH_CONFIRM))

      if (lt(allowance, value)) {
        const approve = await this.sendApprove(
          tokenAddress,
          from,
          address,
          value
        )
        if (!approve) return resolve(false)
      }

      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const functionSelector = 'transferToken(address,uint256,string)'
      const parameter = [
        { type: 'address', value: this.tronWeb.address.toHex(tokenAddress) },
        { type: 'uint256', value },
        { type: 'string', value: String(orderId) },
      ]
      const txid = await this.sendContractMethod(
        address,
        functionSelector,
        parameter,
        collectionABI
      )
      if (txid) {
        eventEmitter.emit(
          'status',
          apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
            txid,
          })
        )
      } else {
        eventEmitter.emit('error', apiResponse.fail(RESPONSE_TYPE.FAILURE), {
          txid,
        })
      }
      resolve(txid || false)
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }

  transferToken(data, promiEvent) {
    return this._transferToken(data, 'deposit', promiEvent)
  }

  // 汇款
  remitToken(data, promiEvent) {
    return this._transferToken(data, 'remittance', promiEvent)
  }

  // 提币
  async withdrawToken({ address, withdrawAddress, withdrawInfo }, promiEvent) {
    const apiResponse = new APIResponse('withdrawal')
    try {
      if (!this.tronWeb || !this.accountInfo || !promiEvent?.eventEmitter) {
        throw new Error('invalid provider')
      }
      const { eventEmitter, resolve } = promiEvent
      const functionSelector = 'withdrawToken(bool,(address,uint256)[],address)'
      const parameter = [
        { type: 'bool', value: false },
        { type: '(address,uint256)[]', value: withdrawInfo },
        { type: 'address', value: withdrawAddress },
      ]
      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.TRANSACTION))
      const txid = await this.sendContractMethod(
        address,
        functionSelector,
        parameter,
        collectionABI
      )
      if (txid) {
        eventEmitter.emit(
          'status',
          apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
            txid,
          })
        )
      } else {
        eventEmitter.emit('error', apiResponse.fail(RESPONSE_TYPE.FAILURE), {
          txid,
        })
      }
      resolve(txid || false)
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }

  // 部署合约
  async deployContract(options, successHandle, errorHandle, callbackHandle) {
    const apiResponse = new APIResponse('deployment')
    try {
      if (!this.tronWeb || !this.accountInfo) {
        throw new Error('invalid provider')
      }
      const { withdrawalList, ownerList, newGatewayAddress } = options
      callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const from = this.accountInfo.account

      // 手续费限制
      const feeLimit = 1e9
      // 判断余额是否足够
      const balance = await this.tronWeb.trx.getBalance(from)
      if (lt(balance, feeLimit)) {
        throw new BalanceMinimum('balance not enough', { min: '1000 TRX' })
      }

      // 合约参数：( withdrawArr: 提币地址列表, ownerArr: 财务人员列表 )
      const withdrawArr = getAddressArr(withdrawalList)
      const ownerArr = getAddressArr(ownerList)
      const parameters = [withdrawArr, ownerArr, newGatewayAddress]

      const transaction =
        await this.tronWeb.transactionBuilder.createSmartContract(
          {
            abi: collectionABI,
            bytecode,
            feeLimit,
            parameters,
            name: 'BlockATMCustomer',
            callValue: 0,
          },
          this.tronWeb.address.toHex(from)
        )
      console.log('transaction', transaction)
      const signedTransaction = await this.signTransaction(transaction)
      if (!signedTransaction) {
        throw new Error('signTransaction is null')
      }
      console.log('signTransaction', signedTransaction)

      const contract_info = await this.tronWeb.trx.sendRawTransaction(
        signedTransaction
      )
      console.log('contract_info', contract_info)

      if (!contract_info?.result || !contract_info?.txid) {
        throw new Error('sendRawTransaction failure')
      }
      callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM), {
        txid: contract_info.txid,
      })
      let newContractId = null
      // 添加合约
      await addContract({
        ownerList,
        txId: contract_info?.txid,
        withdrawalList,
      })
        .then((res) => {
          newContractId = res.data
        })
        .then(() => {
          return this.pollTxidStatus(contract_info?.txid)
        })
        .then(async (flag) => {
          if (flag) {
            // await delay(15000)
            callbackHandle(apiResponse.ok(RESPONSE_TYPE.SUCCESS), {
              txid: contract_info.txid,
            })
            const contractAddress = contract_info?.transaction?.contract_address
            if (newContractId && contractAddress) {
              const formatContract =
                this.tronWeb.address.fromHex(contractAddress)
              completeContract({
                contractAddress: formatContract,
                id: newContractId,
              }).then(() => {
                successHandle(formatContract)
              })
            }
          } else {
            callbackHandle(apiResponse.fail(RESPONSE_TYPE.FAILURE), {
              txid: contract_info.txid,
            })
            errorHandle()
          }
        })
        .catch((error) => {
          callbackHandle(apiResponse.error(error))
          errorHandle()
        })
    } catch (error) {
      errorHandle()
      callbackHandle(apiResponse.error(error))
      console.log('error', error)
    }
  }

  // 部署代付合约
  async deployPayoutContract(
    options,
    successHandle,
    errorHandle,
    callbackHandle
  ) {
    const apiResponse = new APIResponse('deployment')
    try {
      if (!this.tronWeb || !this.accountInfo) {
        throw new Error('invalid provider')
      }
      callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const {
        ownerList,
        newGatewayAddress,
        newFeeTokenAddress,
        newFeeToAddress,
      } = options

      const from = this.accountInfo.account
      // 手续费限制
      const feeLimit = 150e7
      // 判断余额是否足够
      const balance = await this.tronWeb.trx.getBalance(from)
      if (lt(balance, feeLimit)) {
        throw new BalanceMinimum('balance not enough', { min: '1500 TRX' })
      }

      // 合约参数：(ownerArr: 财务人员列表 )
      const ownerArr = getAddressArr(ownerList)
      const parameters = [
        ownerArr,
        newGatewayAddress,
        newFeeTokenAddress,
        newFeeToAddress,
      ]

      const transaction =
        await this.tronWeb.transactionBuilder.createSmartContract(
          {
            abi: payoutABI,
            bytecode: payoutByteCode,
            feeLimit,
            parameters,
            name: 'BlockATMPayout',
            callValue: 0,
          },
          this.tronWeb.address.toHex(from)
        )

      const signedTransaction = await this.signTransaction(transaction)

      if (!signedTransaction) {
        throw new Error('signedTransaction is null')
      }
      const contract_info = await this.tronWeb.trx.sendRawTransaction(
        signedTransaction
      )
      if (!contract_info?.result || !contract_info?.txid) {
        throw new Error('sendRawTransaction failure')
      }
      callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION_CONFIRM), {
        txid: contract_info.txid,
      })
      let newContractId = null
      // 添加合约
      await addPayoutContract({
        ownerList,
        txId: contract_info?.txid,
      })
        .then((res) => {
          newContractId = res.data
        })
        .then(() => {
          return this.pollTxidStatus(contract_info?.txid)
        })
        .then(async (flag) => {
          if (flag) {
            // await delay(15000)
            callbackHandle(apiResponse.ok(RESPONSE_TYPE.SUCCESS), {
              txid: contract_info.txid,
            })
            const contractAddress = contract_info?.transaction?.contract_address
            if (newContractId && contractAddress) {
              const formatContract =
                this.tronWeb.address.fromHex(contractAddress)
              completeContract({
                contractAddress: formatContract,
                id: newContractId,
              }).then(() => {
                successHandle(formatContract)
              })
            }
          } else {
            callbackHandle(apiResponse.fail(RESPONSE_TYPE.FAILURE), {
              txid: contract_info.txid,
            })
            errorHandle()
          }
        })
        .catch((error) => {
          callbackHandle(apiResponse.error(error))
          errorHandle()
        })
    } catch (error) {
      errorHandle()
      callbackHandle(apiResponse.error(error))
      console.log('error', error)
    }
  }

  // 烧毁合约
  async destructContract(params, successHandle, errorHandle, callbackHandle) {
    const apiResponse = new APIResponse('destruct')
    try {
      if (!this.tronWeb || !this.accountInfo) {
        throw new Error('invalid provider')
      }
      callbackHandle(apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const abi = params?.type === 'payout' ? payoutABI : collectionABI

      const txId = await this.sendContractMethod(
        params.address,
        'setActiveFlag',
        [],
        abi
      )
      if (txId) {
        callbackHandle(apiResponse.ok(RESPONSE_TYPE.SUCCESS), {
          txid: txId,
        })
        // 删除合约
        await deleteContract({ id: params.id, txId }).then(() => {
          completeDeleteContract({ param: params.id }).then(() => {
            successHandle()
          })
        })
      } else {
        callbackHandle(apiResponse.fail(RESPONSE_TYPE.FAILURE))
      }
    } catch (error) {
      errorHandle()
      callbackHandle(apiResponse.error(error))
      console.log('error', error)
    }
  }

  // 校验格式是否正确
  isValidAddress(address) {
    return TronWeb.utils.crypto.isAddressValid(address)
  }

  // 轮询txid方法
  async pollTxidStatus(txHash, maxAttempts = 50, waitInterval = 5000) {
    return new Promise(async (resolve, reject) => {
      await delay(1000)
      let attempts = 0
      while (attempts < maxAttempts) {
        try {
          const txInfo = await this.tronWeb.trx.getTransaction(txHash)
          console.log('tx', txHash, txInfo)
          if (txInfo) {
            switch (txInfo.ret[0].contractRet) {
              case 'SUCCESS':
                resolve(true)
                return
              case 'REVERT':
              case 'OUT_OF_ENERGY':
              case 'OUT_OF_TIME':
                resolve(false)
                return
            }
          }
          await delay(waitInterval)
          attempts++
        } catch (error) {
          // 忽略网络错误等
          await delay(waitInterval)
          attempts++
        }
      }
      resolve(null)
    })
  }

  // 获取代付合约地址转账钱包地址
  async getPayoutWalletAddress({ contractAddress, payoutControl }) {
    try {
      const contract = await this.tronWeb.contract().at(payoutControl)
      const res = await contract.methods.getPayout(contractAddress).call()
      return this.tronWeb.address.fromHex(res)
    } catch (error) {
      throw error
    }
  }

  // 设置自动代付地址
  async setPayoutWalletAddress(
    { contractAddress, autoAddress, payoutControl },
    promiEvent
  ) {
    const apiResponse = new APIResponse('integration')
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.tronWeb || !this.accountInfo || !promiEvent.eventEmitter) {
        throw new Error('invalid provider')
      }

      const { eventEmitter, resolve } = promiEvent
      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const functionSelector = !autoAddress
        ? 'deletePayout(address)'
        : 'setPayout(address,address)'
      const parameter = !autoAddress
        ? [
            {
              type: 'address',
              value: this.tronWeb.address.toHex(contractAddress),
            },
          ]
        : [
            { type: 'address', value: this.tronWeb.address.toHex(autoAddress) },
            {
              type: 'address',
              value: this.tronWeb.address.toHex(contractAddress),
            },
          ]

      const txid = await this.sendContractMethod(
        payoutControl,
        functionSelector,
        parameter,
        payoutControlABI
      )
      if (txid) {
        eventEmitter.emit(
          'status',
          apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
            txid,
          })
        )
      } else {
        eventEmitter.emit('error', apiResponse.fail(RESPONSE_TYPE.FAILURE), {
          txid,
        })
      }
      resolve(txid || false)
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }

  // 获取自动代付授权金额
  async getPayoutLimit({ contractAddress }) {
    try {
      const contract = await this.tronWeb.contract(payoutABI, contractAddress)
      const res = await contract.methods.payoutLimit().call()
      return toNonExponential(divide(this.tronWeb.toDecimal(res), pow(10, 18)))
    } catch (error) {
      throw error
    }
  }

  // 设置自动代付地址
  async setPayoutLimit({ contractAddress, limit }, promiEvent) {
    const apiResponse = new APIResponse('approval')
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.tronWeb || !this.accountInfo || !promiEvent.eventEmitter) {
        throw new Error('invalid provider')
      }

      const { eventEmitter, resolve } = promiEvent
      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const functionSelector = 'setPayoutLimit(uint256)'

      const parameter = [
        {
          type: 'uint256',
          value: mul(limit, pow(10, 18)),
        },
      ]

      const txid = await this.sendContractMethod(
        contractAddress,
        functionSelector,
        parameter,
        payoutABI
      )
      if (txid) {
        eventEmitter.emit(
          'status',
          apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
            txid,
          })
        )
      } else {
        eventEmitter.emit('error', apiResponse.fail(RESPONSE_TYPE.FAILURE), {
          txid,
        })
      }
      resolve(txid || false)
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }

  // 设置授权/拒绝方法
  async confirmPendingOrder({contractAddress, value, msgType }, promiEvent) {
    const apiResponse = new APIResponse(msgType)
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.tronWeb || !this.accountInfo || !promiEvent.eventEmitter) {
        throw new Error('invalid provider')
      }

      const { eventEmitter, resolve } = promiEvent
      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.TRANSACTION))

      const functionSelector = msgType === 'reject' ? 'cancelPendingOrder(uint256)' : 'confirmPendingOrder(uint256)'

      const parameter = [
        {
          type: 'uint256',
          value,
        },
      ]

      const txid = await this.sendContractMethod(
        contractAddress,
        functionSelector,
        parameter,
        payoutABI
      )
      if (txid) {
        eventEmitter.emit(
          'status',
          apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
            txid,
          })
        )
      } else {
        eventEmitter.emit('error', apiResponse.fail(RESPONSE_TYPE.FAILURE), {
          txid,
        })
      }
      resolve(txid || false)
    } catch (error) {
      console.log('error', error)
      promiEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiEvent.reject(error)
    }
  }
}
