const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login/login.vue'),
  },
  {
    path: '/',
    name: 'ContractPayment',
    component: () => import('../views/dashboard/dashboard.vue'),
  },
  {
    path: '/contract-payment/step/:step*',
    name: 'ContractPaymentStep',
    component: () => import('../views/step/step.vue'),
  },
  {
    path: '/contract-payment/assets',
    name: 'ContractPaymentAssets',
    component: () => import('../views/contractPayment/assets'),
  },
  {
    path: '/contract-payment/contract',
    name: 'ContractPaymentContract',
    component: () => import('../views/contract/contract.vue'),
  },
  {
    path: '/contract-payment/remittance',
    name: 'ContractPaymentRemittance',
    component: () => import('../views/remittance/remittance.vue'),
  },
  {
    path: '/contract-payment/payout',
    name: 'ContractPaymentPayout',
    meta: {
      keepAlive: true,
    },
    component: () => import('../views/payout/payout.vue'),
  },
  // {
  //   path: '/passToCredit',
  //   name: 'PassToCredit',
  //   component: () => import('../views/passToCredit/passToCredit.vue'),
  // },
  {
    path: '/contract-payment/setting',
    name: 'ContractPaymentSetting',
    component: () => import('../views/setting/setting.vue'),
  },
  {
    path: '/qrcode-payment',
    name: 'QrCodePayment',
    component: () => import('../views/qrCodePayment/dashboard'),
  },
  {
    path: '/qrcode-payment/step/:step*',
    name: 'QrCodePaymentStep',
    component: () => import('../views/qrCodePayment/steps'),
  },
  {
    path: '/qrcode-payment/assets',
    name: 'QrCodePaymentAssets',
    component: () => import('../views/qrCodePayment/assets'),
  },
  {
    path: '/qrcode-payment/payout',
    name: 'QrCodePaymentPayout',
    component: () => import('../views/qrCodePayment/payout'),
  },
  {
    path: '/qrcode-payment/setting',
    name: 'QrCodePaymentSetting',
    component: () => import('../views/qrCodePayment/setting'),
  },
  {
    path: '/qrcode-payment/setPassword',
    name: 'QrCodePaymentSetPassword',
    component: () => import('../views/qrCodePayment/resetPassword'),
  },
  {
    path: '/qrcode-payment/resetPassword',
    name: 'QrCodePaymentResetPassword',
    component: () => import('../views/qrCodePayment/resetPassword'),
  },
  {
    path: '/qrcode-payment/bind2fa',
    name: 'QrCodePaymentBind2FA',
    component: () => import('../views/qrCodePayment/bind2FA'),
  },
  {
    path: '/qrcode-payment/forgetPassword',
    name: 'QrForgetPassword',
    component: () => import('../views/qrCodePayment/forgetPassword'),
  },
]
export default routes
