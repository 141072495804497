/** 模板路径 */
export const Template_PATH = '/template/'

/** 文档访问链接 */
export const DOCS_URL = 'https://blockatm.readme.io'

/** passToCredit官网链接 */
export const PASSTO_CREDIT_URL = 'https://passtocredit.io/'

/** blockATM官网链接 */
export const OFFICIAL_WEBSITE = 'https://blockatm.net'

/** tronLink教程 */
export const TUTORIAL_URL = 'https://blockatm.gitbook.io/blockatm/tron-network'

/** qrcode **/
export const ONLINE_PAYMENT =
  'https://blockatm.gitbook.io/blockatm/getting-started/receiving-crypto/how-to-use-the-qr-code-to-accept-online-payment'

/** payout sdk **/
export const PAYOUT_SDK = 'https://blockatm.readme.io/reference/overview-1'

export const DEFAULT_TIME_CONFIG = [
  new Date(2000, 1, 1, 0, 0, 0, 0),
  new Date(2000, 2, 1, 23, 59, 59, 59),
]
