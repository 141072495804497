import "core-js/modules/es.function.name.js";
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ae0e606e"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["src"];
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'networkIcon',
  props: {
    size: {
      type: String,
      default: 'middle'
    },
    name: String,
    type: {
      type: String,
      default: ''
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var store = useStore();
    var network = computed(function () {
      return props.name || store.state._config.contractConfig.network;
    });
    return function (_ctx, _cache) {
      return _unref(network) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['flex items-center justify-center', __props.size])
      }, [_createElementVNode("img", {
        class: "h-full",
        src: '/images/network/' + _unref(network).toLowerCase() + (__props.type ? '-' + __props.type : '') + '.svg'
      }, null, 8, _hoisted_1)], 2)) : _createCommentVNode("", true);
    };
  }
};