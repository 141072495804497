import { createStore } from 'vuex'
import config from './modules/config.js'
import info from './modules/info.js'

export default createStore({
  modules: {
    _config: config,
    _info: info,
  },
  state: {
    style: {},
  },
})
