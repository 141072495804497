import store from '@/store'
// const rolesInfo = {
//   /* 1-运营人员 2-账号管理员 3-财务人员  */
//   operator:1,
//   manager:2,
//   financial:3,
//   // "1":['operator'],
//   // "2":['manager'],
//   // "3":['financial'],
// }

export default (el, binding) => {
  // 这会在 `mounted` 和 `updated` 时都调用
  // console.log(el,binding);
  const { modifiers } = binding

  if (!check(binding.value)) {
    if (modifiers.disabled) {
      el.disabled = true
      el.classList.add('disabled')
    } else {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
}

export const check = (value) => {
  const roleTypes = store.state._info.merchantInfo.roleTypes || [1, 2, 3]
  if (value && Array.isArray(value)) {
    const hasPermission = roleTypes.some((role) => {
      return value.includes(role)
    })
    return hasPermission
  } else {
    console.error(new Error(`need roles! Like v-role="[1,2]"`))
    return true
  }
}
