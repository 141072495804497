import store from '@/store'
import createSocket from './createSocket'
import { parseJSON, loadToken } from '@/utils/common'
import eventEmitter from '@/utils/eventEmitter'
// websocket消息事件
class SocketEvent {
  constructor() {
    this.ws = null
    this.seq_id = 0
    this.timeOut = 60 * 1000
    this.timer = null
    this.ping = null
    this.requests = {}
    this._options = {
      sendTimeout: 15000,
      debug: false,
    }
  }

  // 初始化
  static init(options) {
    const provider = new SocketEvent()
    provider.initialize(options)
    return provider
  }

  async getUrlProvider() {
    // 重置更换url
    const token = loadToken()
    const wsURL = `${process.env.VUE_APP_apiURL.replace(
      'https:',
      'wss:'
    )}/ws/subscribe?Accesstoken=${token}`
    return wsURL
  }

  initialize(options) {
    this.close()
    this.ws = createSocket(this.getUrlProvider, options)
    this._options = {
      ...this._options,
      ...options,
    }
    this.ws.addEventListener('open', this.onOpen)
    this.ws.addEventListener('message', this.onMessage)
  }

  // ws发送数据格式
  getParam(data) {
    this.seq_id++
    const param = {
      data,
      seq_id: this.seq_id, // 发送端的索引编号，类型：uint32
    }
    return param
  }

  _debug() {
    var args = []
    for (var _i = 0; _i < arguments.length; _i++) {
      args[_i] = arguments[_i]
    }
    if (this._options.debug) {
      console.log.apply(console, args)
    }
  }

  // 发送消息
  send(data) {
    if (this.ws && this.ws.readyState === this.ws.OPEN) {
      this.ws.send(JSON.stringify(data))
      this._debug('send', data)
    }
    this._debug('error websock not ready', data)
  }

  // websocket连接成功
  onOpen = (e) => {
    this.initPing()
  }

  // 收取到消息
  onMessage = (e) => {
    const { data } = e
    if (data === 'invalid Token!') {
      this.close()
      return
    }
    const payload = parseJSON(data, {})
    const { messageType, body } = payload
    switch (messageType) {
      case 'ACCOUNT_CHANGE':
        // 余额变更
        store.commit('_info/Update_merchantBalance', body)
        // 刷新合约余额
        eventEmitter.emit('loadBalance')
        break
      case 'CONTRACT_CHANGE':
        // 合约变动
        eventEmitter.emit('contractSuccess', parseJSON(body))
        break
    }
  }

  // 心跳机制
  initPing() {
    this.send({ messageType: 'PING' })
    if (this.ping) clearInterval(this.ping)
    this.ping = setInterval(() => {
      this.send({ messageType: 'PING' })
    }, 15000)
  }

  close() {
    this.ws && this.ws.close()
  }
}

export default SocketEvent
