import request from '@/utils/request'

/* 根据网络获取合约配置 */
export function getContractConfig(data) {
  return request({
    url: '/admin/mng/config/network',
    method: 'get',
    params: data,
  })
}

/* 根据网络获取币种配置 */
export function getSymbolConfig(data) {
  return request({
    url: '/admin/mng/config/symbolList',
    method: 'get',
    toastErr: true, // 设置为false时，接口报错不会自动提示错误信息
    params: data,
  })
}

/* 根据所有网络获取合约配置 */
export function getAllNetworks(data) {
  return request({
    url: '/admin/mng/config/allNetworks',
    method: 'get',
    params: data,
  })
}

/* 获取服务升级信息 */
export function getUpgradeInfo(data) {
  return request({
    url: '/mconfig/upgrade',
    method: 'get',
    params: data,
  })
}

/* 登录签名字符串 */
export function signatureStr(data) {
  return request({
    url: '/admin/mng/config/signatureStr',
    method: 'get',
    params: data,
    toastErr: false,
  })
}
