import {
  getContractConfig as getContractConfigAPI,
  getSymbolConfig,
  getAllNetworks,
  getUpgradeInfo,
  signatureStr,
} from '@/api/config'
import { getBankList } from '@/api/remittance'

export default {
  namespaced: true,
  state: {
    defaultTimeConfig: [
      new Date(2000, 1, 1, 0, 0, 0, 0),
      new Date(2000, 2, 1, 23, 59, 59, 59),
    ],
    connectConfig: {
      infuraId: 'ab414eaf3a8c493cb2a24ca4d25b7825',
      projectId: '9db36710e1e16f0992ad146d4d62ad82',
      tronApikey: '38ba5ffa-599c-4fa4-91f7-846be232f434',
    },
    // 合约配置
    contractConfig: {
      rechargeContractAddress: '',
    },
    // 业务收款币种
    receiptSymbolConfig: [],
    // 汇款结算币种
    settlementSymbolConfig: [
      {
        symbol: '',
        tokenAddress: '',
      },
    ],
    showBusinessBalanceTips: true, // 是否显示跑马灯提示
    legalCurrencyList: ['CNY'],
    bankList: [], // 银行列表
    allNetwork: [], // 所有网络参数
    maintenance: 0, // 维护状态  1 维护中 2 正常
    maintenanceInfo: {}, // 维护信息
    routerInit: false, // 路由初始化
    signatureStr: '', // 签名字符串
  },
  mutations: {
    Update_allNetwork(state, data) {
      state.allNetwork = data
    },
    Update_contractConfig(state, data) {
      state.contractConfig = data
    },
    Update_receiptSymbolConfig(state, data) {
      state.receiptSymbolConfig = data
    },
    Update_settlementSymbolConfig(state, data) {
      state.settlementSymbolConfig = data
    },
    Update_showBusinessBalanceTips(state, data) {
      state.showBusinessBalanceTips = data
    },
    Update_bankList(state, data) {
      state.bankList = data
    },
    Update_maintenance(state, data) {
      state.maintenance = data
    },
    Update_maintenanceInfo(state, data) {
      state.maintenanceInfo = data
    },
    Update_signatureStr(state, data) {
      state.signatureStr = data
    },
  },
  actions: {
    // 获取合约配置
    queryContract({ commit }, params) {
      getContractConfigAPI(params).then((res) => {
        if (res.check()) {
          commit('Update_contractConfig', res.data)
        }
      })
    },
    // 获取合约配置
    queryAllNetwork({ commit }, params) {
      return getAllNetworks(params).then((res) => {
        if (res.check()) {
          const currentHost =
            process.env.VUE_APP_apiURL ||
            location.protocol + '//' + location.host
          const allNetwork = res.data.map((item) => {
            // 更新eth rpc
            const rpcUrl =
              item.addressType === 'ETH'
                ? `${currentHost}/wallet/mng/infura/node/${item.chainId}`
                : item.rpcUrl
            return {
              ...item,
              rpcUrl,
            }
          })
          commit('Update_allNetwork', allNetwork)
          return allNetwork
        }
      })
    },
    // 获取币种配置
    querySymbol({ commit }, params) {
      getSymbolConfig(params).then((res) => {
        if (res.check()) {
          const data = { receiptSymbolConfig: [], settlementSymbolConfig: [] }
          res.data.forEach((item) => {
            if (item.bizType === 1) {
              data.receiptSymbolConfig.push(item)
            } else if (item.bizType === 2) {
              data.settlementSymbolConfig.push(item)
            }
          })
          if (data.receiptSymbolConfig.length) {
            commit('Update_receiptSymbolConfig', data.receiptSymbolConfig)
          }
          if (data.settlementSymbolConfig.length) {
            commit('Update_settlementSymbolConfig', data.settlementSymbolConfig)
          }
        }
      })
    },
    // 获取银行列表
    queryBankList({ commit }) {
      return getBankList().then((res) => {
        if (res.check()) {
          const dataList = res.data || []
          let list = {}
          dataList.filter((item) => {
            list[item.code] = item.name
          })
          commit('Update_bankList', list)
        }
        return res
      })
    },
    // 服务升级信息
    queryUpgrade({ commit }) {
      return getUpgradeInfo().then((res) => {
        if (res.check()) {
          commit('Update_maintenanceInfo', res.data)
        }
      })
    },
    // 签名字符串
    signatureStr({ commit }) {
      return signatureStr().then((res) => {
        if (res.check()) {
          commit('Update_signatureStr', res.data)
          return res.data
        }
      })
    },
  },
  getters: {
    getReceiptSymbolList(state) {
      // 获取当前链的token列表
      let list = {}
      state.receiptSymbolConfig.forEach((item) => {
        list[item.symbol] = item.symbol
      })
      return list
    },
    getNetwork(state) {
      // 获取ETH/TRON的网络列表
      let list = {}
      const { addressType } = state.contractConfig
      state.allNetwork.forEach((item) => {
        if (addressType === item.addressType) {
          list[item.chainId] = item.name
        }
      })
      return list
    },
    // 获取所有网络列表
    getAllNetworks(state) {
      let list = {}
      state.allNetwork.filter((item) => {
        list[item.chainId] = item.name
      })
      return list
    },
    // 获取当前网络信息
    getNetworkInfo(state, getters, rootState) {
      const { allNetwork = [] } = state
      const contractConfig = allNetwork.find(
        (item) => item.chainId == rootState._info.connectInfo.chainId
      )
      return contractConfig || {}
    },
  },
}
