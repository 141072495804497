import { useMediaQuery } from '@vueuse/core'
import { computed } from 'vue'

const breakpoints = {
  xs: 640,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1536,
}

const useMediaQuerySize = (size) => {
  const mediaQuery = computed(() => {
    const currentSize = breakpoints[size] || breakpoints.xs
    return `(max-width: ${currentSize}px)`
  })
  return useMediaQuery(mediaQuery)
}

export default useMediaQuerySize
