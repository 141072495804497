import request from '@/utils/request'

/* 未支付页面分页列表 */
export function getEmittanceOrderPage(data) {
  const param = {
    url: '/order/mng/emittanceOrder/pages',
    method: 'post',
    data,
  }
  return request(param)
}

/* 已支付页面分页列表 */
export function getPaidEmittanceOrderPage(data) {
  const param = {
    url: '/order/mng/emittanceOrder/pay/pages',
    method: 'post',
    data,
  }
  return request(param)
}

/* 计算汇款金额 */
export function calEmittanceAmount(data) {
  return request({
    url: '/order/mng/emittanceOrder/calAmount',
    method: 'post',
    data,
  })
}

/* 锁定汇款单 */
export function lockEmittanceOrder(data) {
  return request({
    url: '/order/mng/emittanceOrder/lock',
    method: 'post',
    data,
  })
}

/* 解锁汇款单 */
export function unlockEmittanceOrder(data) {
  return request({
    url: 'order/mng/emittanceOrder/unlock',
    method: 'post',
    data,
  })
}

/* 单笔汇款申请 */
export function addEmittanceOrder(data) {
  return request({
    url: '/order/mng/emittanceOrder/add',
    method: 'post',
    data,
  })
}

/* 获取银行列表 */
export function getBankList(data) {
  return request({
    url: '/order/mng/emittanceOrder/bank/list',
    method: 'get',
    data,
  })
}

/* 下载汇款单模板 */
export function downloadEmittanceTemplate(data) {
  return request({
    url: '/order/mng/emittanceOrder/template',
    method: 'Post',
    data,
  })
}
