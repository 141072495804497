import EventEmitter from 'eventemitter3'
import EthAdapter from './eth'
import TronAdapter from './tron'

class Web3Connect extends EventEmitter {
  constructor({
    chainId,
    network, // ETH TRON
    wallet, // MetaMask TronLink WalletConnect 【Ledger  TokenPocket BitKeep Okx Wallet 待验证 】
    options = {},
  }) {
    super()
    if (!wallet || !network) {
      throw new Error(`[Web3Connect] wallet and network is required.`)
    }
    this.adapter = null
    if (network === 'ETH') {
      this.adapter = new EthAdapter({ wallet, options, chainId })
    } else if (network === 'TRON') {
      this.adapter = new TronAdapter({ wallet, options, chainId })
    }
    this.chainId = chainId
    this.network = network
    this.wallet = wallet
  }

  // 连接
  async connect() {
    if (this.adapter) {
      await this.adapter.connect()
      await this.subscribeProvider()
    }
  }

  // 切换网络
  async switchNetwork() {
    try {
      if (this.adapter) {
        await this.adapter.switchNetwork(...arguments)
      }
    } catch (e) {
      throw e
    }
  }

  handleConnect = (event) => {
    // 连接
    this.emit('connect', event)
  }

  handleDisconnect = (e) => {
    // 断开连接
    console.log('handleDisconnect', e)
    this.emit('disconnect', e)
  }

  handleAccountsChanged = async (accounts) => {
    // 断开登录
    if (accounts) {
      await this.adapter.getAccountInfo()
      this.emit(
        'accountsChanged',
        typeof accounts === 'string' ? [accounts] : accounts
      )
    }
  }

  handleChainChanged = async (event) => {
    // 链变化
    await this.adapter.getAccountInfo()
    this.emit('chainChanged', event?.chainId || event)
  }

  // 统一订阅事件
  async subscribeProvider() {
    console.log('subscribeProvider')
    this.removeSubscribeProvider()
    if (!this.adapter.provider.on) return
    this.adapter.provider.on('connect', this.handleConnect)
    this.adapter.provider.on('disconnect', this.handleDisconnect)
    this.adapter.provider.on('accountsChanged', this.handleAccountsChanged)
    this.adapter.provider.on('chainChanged', this.handleChainChanged)
    this.adapter.provider.on('stateChanged', this.handleChainChanged)
  }

  async removeSubscribeProvider() {
    if (this.adapter?.provider?._eventsCount > 0) {
      this.adapter.provider.removeListener('connect', this.handleConnect)
      this.adapter.provider.removeListener('disconnect', this.handleDisconnect)
      this.adapter.provider.removeListener(
        'accountsChanged',
        this.handleAccountsChanged
      )
      this.adapter.provider.removeListener(
        'chainChanged',
        this.handleChainChanged
      )
      this.adapter.provider.removeListener(
        'stateChanged',
        this.handleChainChanged
      )
    }
  }

  // 断开连接
  async disconnect() {
    await this.removeSubscribeProvider()
    await this.adapter?.disconnect()
  }

  // 获取账户信息
  async getAccountInfo() {
    return await this.adapter?.getAccountInfo()
  }

  // 签名
  async signMessage(message, account) {
    return await this.adapter?.signMessage(message, account)
  }

  // 获取代币小数位
  async getDecimals(tokenAddress) {
    return await this.adapter?.getDecimals(tokenAddress)
  }

  // 获取代币余额【合约地址，钱包地址】
  async getTokenBalance(tokenAddress, address) {
    return await this.adapter?.getTokenBalance(tokenAddress, address)
  }

  // 充值
  transferToken() {
    return this.adapter?.transferToken(...arguments)
  }

  // 汇款
  remitToken() {
    return this.adapter?.remitToken(...arguments)
  }

  // 提币
  withdrawToken() {
    return this.adapter?.withdrawToken(...arguments)
  }

  // 部署合约
  deployContract() {
    return this.adapter?.deployContract(...arguments)
  }

  // 部署代付合约
  async deployPayoutContract() {
    return await this.adapter?.deployPayoutContract(...arguments)
  }

  // 烧毁合约
  async destructContract() {
    return await this.adapter?.destructContract(...arguments)
  }

  // 校验地址是否正确
  isValidAddress(address) {
    return this.adapter.isValidAddress(address)
  }

  // 获取代付转账地址
  async getPayoutWalletAddress() {
    return await this.adapter?.getPayoutWalletAddress(...arguments)
  }

  // 设置代付地址
  setPayoutWalletAddress() {
    return this.adapter?.setPayoutWalletAddress(...arguments)
  }

  //切换网络
  async switchNetwork() {
    return this.adapter?.switchNetwork(...arguments)
  }

  // 获取自动代付授权金额限制
  async getPayoutLimit() {
    return await this.adapter?.getPayoutLimit(...arguments)
  }

  // 设置自动代付授权金额
  setPayoutLimit() {
    return this.adapter?.setPayoutLimit(...arguments)
  }

  // 授权/拒绝代付订单
  confirmPendingOrder() {
    return this.adapter?.confirmPendingOrder(...arguments)
  }
}

window.Web3Connect = Web3Connect

export default Web3Connect
