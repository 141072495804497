import request from '@/utils/request'

/* 商户信息查询 */
export function getMerchantInfo(data) {
  return request({
    url: '/admin/mng/merchant/info',
    method: 'get',
    toastErr: true, // 设置为false时，接口报错不会自动提示错误信息
    params: data,
  })
}

/* 初次设置 */
export function merchantSetup(data) {
  return request({
    url: '/admin/mng/merchant/setup',
    method: 'post',
    data,
  })
}

/* 查询人员列表 */
export function getMerchantList(data) {
  return request({
    url: '/admin/mng/merchant/get/member/list',
    method: 'post',
    data,
  })
}

/* 新增人员列表 */
export function addMerchantMember(data) {
  return request({
    url: '/admin/mng/merchant/add/member',
    method: 'post',
    data,
  })
}

// 查询钱包收款地址
export function getWalletAddressList(data) {
  return request({
    url: '/admin/mng/merchant/wallet/address/list',
    method: 'post',
    data,
  })
}

// 删除收款钱包地址
export function deleteWalletAddress(data) {
  return request({
    url: '/admin/mng/merchant/wallet/address/delete',
    method: 'post',
    data,
  })
}

// 删除收款钱包地址检测
export function deleteWalletAddressCheck(data) {
  return request({
    url: '/admin/mng/merchant/wallet/address/delete/check',
    method: 'post',
    data,
  })
}

/* 新增收款钱包地址 */
export function addWalletAddress(data) {
  return request({
    url: '/admin/mng/merchant/wallet/address/add',
    method: 'post',
    data,
  })
}

/* 修改收款钱包别名 */
export function updateWalletAddress(data) {
  return request({
    url: '/admin/mng/merchant/wallet/address/update',
    method: 'post',
    data,
  })
}

/* 修改项目设置 */
export function editMerchantInfo(data) {
  return request({
    url: '/admin/mng/merchant/update',
    method: 'post',
    data,
  })
}

/* 更新人员 */
export function updateMember(data) {
  return request({
    url: '/admin/mng/merchant/update/member',
    method: 'post',
    data,
  })
}

/* 删除人员 */
export function deleteMember(data) {
  return request({
    url: '/admin/mng/merchant/delete/member',
    method: 'post',
    data,
  })
}

/* 删除人员检测 */
export function deleteMemberCheck(data) {
  return request({
    url: '/admin/mng/merchant/delete/member/check',
    method: 'post',
    data,
  })
}

/* 增加联系方式 */
export function addContactInfo(data) {
  return request({
    url: '/admin/mng/merchant/add/contact',
    method: 'post',
    data,
  })
}

/* 二维码收银台设置 */
export function setupQrCodeCashier(data) {
  return request({
    url: '/admin/mng/merchant/qrCashierSetup',
    method: 'post',
    data,
  })
}

/* 查询谷歌验证 */
export function getGoogleAuth(data) {
  return request({
    url: '/admin/mng/merchant/get/google/auth',
    method: 'get',
    data,
  })
}

/* 绑定谷歌验证 */
export function bindGoogleAuth(data) {
  return request({
    url: '/admin/mng/merchant/bind/google/auth',
    method: 'post',
    data,
  })
}

/* 测试Webhook */
export function testWebhook(data) {
  return request({
    url: '/admin/mng/merchant/webhook/test',
    method: 'post',
    data,
  })
}

//

/* 发送邮件 */
export function sendEmail(data) {
  return request({
    url: '/admin/mng/merchant/send/email',
    method: 'post',
    data,
  })
}

// 商户详情查询
export function getMerchantDetail(data) {
  return request({
    url: '/admin/mng/merchant/detail',
    method: 'get',
    toastErr: true, // 设置为false时，接口报错不会自动提示错误信息
    params: data,
  })
}

// 币种自定义列表查询
export function getSymbolList(data) {
  return request({
    url: '/admin/mng/merchant/symbol/list',
    method: 'post',
    data,
  })
}

// 更新币种自定义状态
export function updateSymbol(data) {
  return request({
    url: '/admin/mng/merchant/symbol/add',
    method: 'post',
    data,
  })
}

// 获取公钥
export function getPublicKey(data) {
  return request({
    url: '/admin/mng/merchantKey/viewPubKey',
    method: 'post',
    data,
  })
}

// 生成新公钥
export function generatePublicKey(data) {
  return request({
    url: '/admin/mng/merchantKey/generatePubKey',
    method: 'post',
    data,
  })
}

// 公钥已复制
export function copiedPublicKey(data) {
  return request({
    url: '/admin/mng/merchantKey/copyPubKey',
    method: 'post',
    data,
  })
}

// 开启收银台请求签名
export function enableCashierSign(data) {
  return request({
    url: '/admin/mng/merchantKey/enableCashierSign',
    method: 'post',
    data,
  })
}

// 关闭收银台请求签名
export function disEnableCashierSign(data) {
  return request({
    url: '/admin/mng/merchantKey/disEnableCashierSign',
    method: 'post',
    data,
  })
}

// 查询请求签名公钥
export function queryCashierSign(data) {
  return request({
    url: '/admin/mng/merchantKey/query',
    method: 'get',
    data,
  })
}

// 保存商户请求公钥-V3.2
export function saveCashierSign(data) {
  return request({
    url: '/admin/mng/merchantKey/save',
    method: 'post',
    data,
  })
}

// 关闭dashboard上pending显示
export function closePendingShow(data) {
  return request({
    url: '/admin/mng/merchant/closePendingShow',
    method: 'post',
    data,
  })
}

// 刷新pending显示缓存
export function flushPayoutPendingCache(data) {
  return request({
    url: '/admin/mng/merchant/flushPayoutPendingCache',
    method: 'post',
    data,
  })
}

// 查看dashboard中pending显示状态
export function payoutPendingShow(data) {
  return request({
    url: '/admin/mng/merchant/payoutPendingShow',
    method: 'get',
    data,
  })
}

// 首次设置接口，通过邮件地址进行-V4.1.0
export function firstTimeSetupByEmail(data) {
  return request({
    url: '/admin/mng/merchant/firstTimeSetupByEmail',
    method: 'post',
    data,
  })
}

// 邮箱开户设置密码-V4.1.0
export function firstSetPassword(data) {
  return request({
    url: '/admin/mng/merchant/set/update',
    method: 'post',
    data,
  })
}

// 邮箱开户设置密码-V4.1.0
export function withdrawSendEmailCode(data) {
  return request({
    url: '/admin/mng/merchant/set/sendEmailCode',
    method: 'get',
    data,
  })
}
