import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'

// 默认错误提示
const info = (message, ...rest) => {
  return ElMessage({ message, type: 'error', ...rest })
}

// 成功提示
const success = (message, ...rest) => {
  return ElMessage({ message, type: 'success', ...rest })
}

// alert提示
const alert = (message, title, callback, options) => {
  if (typeof title === 'function') {
    callback = title
    title = 'BLOCKATM'
  }
  return ElMessageBox.alert(message, title, {
    callback: (res) => {
      if (res === 'confirm' && callback) {
        return callback()
      }
    },
    center: true,
    ...options,
  })
}

// confirm
const confirm = (message, title, callback, options) => {
  if (typeof title === 'function') {
    callback = title
    title = 'BLOCKATM'
  }
  return ElMessageBox.confirm(message, title, {
    callback: (res) => {
      if (res === 'confirm' && callback) {
        return callback()
      }
    },
    center: true,
    ...options,
  })
}

export default {
  info,
  success,
  alert,
  confirm,
}
