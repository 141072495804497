import ReconnectingWebSocket from 'reconnecting-websocket'

const DEFAULT_OPTIONS = {
  maxReconnectionDelay: 10000,
  minUptime: 5000,
  reconnectionDelayGrowFactor: 1.3,
  connectionTimeout: 5000,
  maxRetries: Infinity,
  startClosed: false,
  debug: false,
  sendTimeout: 15000, // 发送请求默认超时时间
}

export default function Socket(wsURL, options) {
  const ws = new ReconnectingWebSocket(wsURL, [], {
    ...DEFAULT_OPTIONS,
    ...options,
  })
  return ws
}
