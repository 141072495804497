import store from '@/store'

const LASTEVENT = 'LASTPATHFLOW' // 上一个事件

const GAEvent = {
  on(selector, eventType, callback) {
    // 绑定事件
    document.addEventListener(eventType, (event) => {
      if (event.target.closest(selector)) {
        callback.call(event.target, event)
      }
    })
  },
  init() {
    this.on('[data-ga]', 'click', (e) => {
      // 找三层以内的元素
      const ga =
        e.target?.dataset?.ga ||
        e.target.parentNode?.dataset?.ga ||
        e.target.parentNode?.parentNode?.dataset?.ga
      this.trackEvent(ga)
    })
  },
  trackEvent(ga, rest = {}) {
    // 合并埋点方法 事件名、路径流、其他方法
    if (!ga) return
    const gaArr = ga.split(',').map((item) => item.trim())
    if (gaArr.length === 0) return
    let event = ['backend', gaArr[0]] // 事件名
    this.sendEvent(event.join('_'), {
      pathFlow: sessionStorage.getItem(LASTEVENT) || '',
      ec: gaArr[1] || '', // 事件类型
      el: gaArr[2] || '', // 事件值
      ...rest,
    })
    // 缓存上一个事件名
    sessionStorage.setItem(LASTEVENT, event.join('_'))
  },
  sendEvent(event, obj) {
    // 埋点
    const eventModel = {
      // 当前时间
      timestamp: parseInt(new Date().getTime() / 1000),
      uid: store.state._info.merchantInfo.merchantId,
      walletType: store.state._info.connectInfo.wallet,
      ...obj,
      // debug_mode: true, // 临时开启
    }
    console.log('event:', event, eventModel)
    if (window.gtag) {
      window.gtag('event', event, eventModel)
    } else if (window.dataLayer) {
      window.dataLayer.push({
        event,
        eventModel,
      })
    }
  },
}

export default GAEvent

GAEvent.init()
window.GAEvent = GAEvent
