import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n/i18n'
import router from './router'
import store from './store'
import BigNumber from 'bignumber.js'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'
import mixin from './mixin.js'
import { debounce } from './utils/common'
import role from './directives/role'
import Vue3Marquee from 'vue3-marquee'
import 'vue3-marquee/dist/style.css'
import { v4 as guid } from 'uuid'
import './utils/ga'

BigNumber.config({ EXPONENTIAL_AT: [-16, 26] })

const app = createApp(App)
app.use(i18n).use(store).use(router)
app.use(Vue3Marquee)
app.directive('role', role)
app.mixin(mixin)
app.mount('#app')

if (!window.crypto?.randomUUID) {
  window.crypto.randomUUID = guid
}

/* 处理el-table使用时，不断触发元素重绘引起的loop limit exceeded */
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}
