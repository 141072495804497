import store from '@/store'

export function getChainNetwork(chainId) {
  const supportedChains = store.state._config.allNetwork
  const chain = supportedChains.find((chain) => chain.chainId == chainId)
  return chain?.addressType || ''
}

export function getScanUrl(chainId) {
  const supportedChains = store.state._config.allNetwork
  const chain = supportedChains.find((chain) => chain.chainId == chainId)
  return chain?.explorer || ''
}

export function checkSupported(chainId) {
  const supportedChains = store.state._config.allNetwork
  const chain = supportedChains.findIndex((chain) => chain.chainId == chainId)
  return chain === -1 ? false : true
}

export function getChainIdByNetwork(network) {
  const supportedChains = store.state._config.allNetwork
  const chain = supportedChains.find((chain) => chain.network == network)
  return chain?.chainId || ''
}

export function getRpcMap(network) {
  const supportedChains = store.state._config.allNetwork
  const rpcMap = {}
  supportedChains.forEach((chain) => {
    if (chain.rpcUrl && network === chain.addressType) {
      rpcMap[chain.chainId] = chain.rpcUrl
    }
  })
  return rpcMap
}

export function getBrowserUrl(chainId) {
  const baseUrl = getScanUrl(chainId)
  const network = getChainNetwork(chainId)
  let browserUrl = {}
  switch (network) {
    case 'ETH':
      browserUrl = {
        account: baseUrl + 'address/',
        contract: baseUrl + 'address/',
        txHash: baseUrl + 'tx/',
      }
      break
    case 'TRON':
      browserUrl = {
        account: baseUrl + '#/address/',
        contract: baseUrl + '#/contract/',
        txHash: baseUrl + '#/transaction/',
      }
      break
  }
  return browserUrl
}

export function getChainName(chainId) {
  const supportedChains = store.state._config.allNetwork
  const chain = supportedChains.find((chain) => chain.chainId == chainId)
  return chain?.name || ''
}
