import { createRouter, createWebHistory } from 'vue-router'
import { loadToken } from '@/utils/common'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from) => {
  const token = loadToken()
  // console.log('to', to, 'from', from);
  // 未登录可以访问的路由列表
  const guestRoutes = ['Login', 'ContractPaymentStep', 'QrCodePaymentStep', 'QrCodePaymentSetPassword', 'QrCodePaymentResetPassword', 'QrCodePaymentBind2FA', 'QrForgetPassword']
  if (guestRoutes.indexOf(to.name) === -1 && !token) {
    return {
      name: 'Login',
      query: {
        redirect: to.fullPath,
      },
    }
  }
  // 返回 false 以取消导航
  return true
})
export default router
