import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import Logo from './logo.vue';
import NavList from './navList.vue';
export default {
  __name: 'leftNavList',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", null, [_createVNode(Logo), _createVNode(NavList, {
        class: "mt-10",
        type: "card"
      })]);
    };
  }
};