import request from '@/utils/request'

/* 获取代付配置 */
export function getPayoutConfig(data) {
  return request({
    url: '/wallet/mng/payout/config/info',
    method: 'post',
    data,
    toastErr: true,
  })
}

/* 获取google验证码 */
export function getGoogleAuth(data) {
  return request({
    url: '/wallet/mng/payout/config/getGoogleAuth',
    method: 'post',
    data,
    toastErr: false,
  })
}

/** 绑定google验证码 */
export function bindGoogleAuth(data) {
  return request({
    url: '/wallet/mng/payout/config/bindGoogleAuth',
    method: 'post',
    data,
  })
}

/** 设置其他网络地址 */
export function setOtherAccount(data) {
  return request({
    url: '/wallet/mng/payout/config/setAddress',
    method: 'post',
    data,
  })
}

/** 生成客户端 */
export function generateApp(data) {
  return request({
    url: '/wallet/mng/payout/config/generate',
    method: 'post',
    data,
  })
}

/** 获取 github 的 job */
export function getGitHubJob(data) {
  return request({
    url: '/wallet/mng/payout/config/getJob',
    method: 'post',
    data,
    toastErr: true,
  })
}

/** 获取客户端下载地址 */
export function getDownloadUrl(data) {
  return request({
    url: '/wallet/mng/payout/config/getDownloadUrl',
    method: 'post',
    data,
  })
}

// 获取action job 信息
export function getActionRuns(data) {
  return request({
    url: '/wallet/mng/payout/config/getActionRuns',
    method: 'post',
    data,
    toastErr: true,
  })
}

// 代付审批列表
export function getPayoutPendingList(data) {
  return request({
    url: '/wallet/mng/payout/pending/page',
    method: 'post',
    data,
  })
}

// 代付拒绝列表
export function getPayoutLogList(data) {
  return request({
    url: '/wallet/mng/payout/log/page',
    method: 'post',
    data,
  })
}

// 刷新代付列表信息
export function refreshPendingList(data) {
  return request({
    url: '/wallet/mng/payout/pending/refresh',
    method: 'post',
    data,
    toastErr: false,
  })
}
