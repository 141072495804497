import request from '@/utils/request'

/* 合约分页列表 */
export function getContractPage(data) {
  return request({
    url: '/wallet/mng/contract/page',
    method: 'post',
    data: Object.assign({ pageNo: 1, pageSize: 999999 }, data),
  })
}

/* 合约详情 */
export function getContractInfo(data) {
  return request({
    url: '/wallet/mng/contract/detail',
    method: 'post',
    data,
  })
}

/* 冻结部署智能合约费用 */
export function lockContract(data) {
  return request({
    url: '/wallet/mng/contract/lock',
    method: 'post',
    data,
  })
}

/* 新增智能合约 */
export function addContract(data) {
  return request({
    url: '/wallet/mng/contract/add',
    method: 'post',
    data,
  })
}

/* 新增智能合约 */
export function addPayoutContract(data) {
  return request({
    url: '/wallet/mng/contract/payout/add',
    method: 'post',
    data,
  })
}

/* 部署智能合约成功 */
export function completeContract(data) {
  return request({
    url: '/wallet/mng/contract/complete',
    method: 'post',
    data,
  })
}

/* 部署智能合约失败 */
export function failContract(data) {
  return request({
    url: '/wallet/mng/contract/fail',
    method: 'post',
    data,
  })
}

/* 燃烧智能合约 */
export function deleteContract(data) {
  return request({
    url: '/wallet/mng/contract/delete',
    method: 'post',
    data,
  })
}

export function completeDeleteContract(data) {
  return request({
    url: '/wallet/mng/contract/delete/complete',
    method: 'post',
    data,
  })
}

/* 智能合约历史记录 */
export function getContractRecord(data) {
  return request({
    url: '/wallet/mng/contract/record/page',
    method: 'post',
    data,
  })
}
